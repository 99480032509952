import React from "react";

export const SoundCloudEmbed = ({ src, embed }) => (
  // <iframe
  //   width="100%"
  //   height="166"
  //   scrolling="no"
  //   frameborder="no"
  //   allow="autoplay"
  //   src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1978975787&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
  // ></iframe>

  <div dangerouslySetInnerHTML={{ __html: embed }} />
);
