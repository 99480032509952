import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import BlogBlock from "../../components/BlogBlock";
import { SoundCloudEmbed } from "../../components/SoundCloudEmbed";

import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";

export default function Component(props) {
  const {
    title,
    content,
    publishedAt,
    updatedAt,
    category: categories,
  } = props.data.contentfulBlog;
  return (
    <Layout title={title}>
      <BlogBlock
        title={title}
        publishedAt={publishedAt}
        updatedAt={updatedAt}
        categories={categories}
      >
        {renderRichText(content, {
          renderText: (text) =>
            text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
          renderNode: {
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
              const target = node.data.target;
              switch (target.__typename) {
                case "ContentfulSoundCloudEmbed":
                  return (
                    <SoundCloudEmbed
                      src={target.src}
                      embed={target.embed.embed}
                    />
                  );
                default:
                  return <div>{JSON.stringify(target)}</div>;
              }
            },
          },
        })}
      </BlogBlock>
    </Layout>
  );
}

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.

export const query = graphql`
  query ($id: String) {
    contentfulBlog(id: { eq: $id }) {
      slug
      title
      publishedAt(formatString: "MMMM D, YYYY")
      updatedAt(formatString: "MMMM D, YYYY")
      category
      content {
        raw
        references {
          ... on ContentfulSoundCloudEmbed {
            contentful_id
            id
            src
            embed {
              embed
            }
            __typename
          }
        }
      }
    }
  }
`;
