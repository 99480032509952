import React from "react";

import * as styles from "./styles.module.scss";

const BlogBlock = ({ title, children, publishedAt, updatedAt, categories }) => {
  return (
    <article className={styles.title}>
      <h1 style={{ textAlign: "left" }}>{title}</h1>
      <div className={styles.metadataContainer}>
        <div className={styles.categories}>
          {categories.map((category) => (
            <span>{category}</span>
          ))}
        </div>
        <div className={styles.dates}>
          🌱 planted {publishedAt}
          {publishedAt !== updatedAt ? (
            <>
              <br />
              💦 last watered {updatedAt}
            </>
          ) : null}
        </div>
      </div>
      {children}
    </article>
  );
};

export default BlogBlock;
